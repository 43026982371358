import {useEffect/*, useState*/} from "react";
// import { useNavigate } from "react-router-dom";
import { languages } from "../Helpers/Lang";


function Footer(props){


	// const navigate = useNavigate();

	// const [currentSlug, setCurrentSlug] = useState('');



	useEffect(() => {
	// eslint-disable-next-line
	},[])

	/*
	useEffect(() => {
		let urlSegments = window.location.href.split('/');
		if( urlSegments[4] !== undefined ){
			setCurrentSlug( urlSegments[4] );
		}else{
			setCurrentSlug( '' );
		}
	// eslint-disable-next-line
	},[window.location.href])
	*/


	/*
	const setPage = (page) => {
		navigate( `/${props.lang}/${page}` );
		window.scrollTo(window.scrollX, 0);
		// history.push( `${process.env.PUBLIC_URL}/${props.lang}/${page}` );
	}
	*/


	return (
		<div className="footer">
			
			ambikronia.hr
			&nbsp;&nbsp;/&nbsp;&nbsp;
			copyright info 2024&copy;
			&nbsp;&nbsp;/&nbsp;&nbsp;
			social icons here
			&nbsp;&nbsp;/&nbsp;&nbsp;

			{languages.length > 1 && 
				languages
					.map( (language, langIdx) => 
					<button 
						key={`footer_lang_${langIdx}`}
						onClick={() => props.setLang(language)}
						className={`footer__cta ${(props.lang === language) ? 'footer__cta--active' : ''}`}
					>

						{language}
					</button>
			)}

		</div>
	);

}

export default Footer;