import { useState, useEffect, useRef } from "react";
// import { useDraggable } from "react-use-draggable-scroll";
import { translations } from "../../Helpers/Lang";
import Media from '../Media';

function ArticleDefault ( props ){

	const descriptionRef = useRef();
	const itemsRef = useRef({});

	const [ pCount, setPCount ] = useState(0);
	
	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line

		const descElem = descriptionRef.current;
		if( descElem !== undefined ){
			const descElemParagraphs = descElem.querySelectorAll(':scope > p');
			setPCount( 
				Math.max( 
					pCount, 
					[...descElemParagraphs].length
				) 
			)
		}
		
	},[ pCount ])

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''}`}> 

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}

			{ props.article.title !== '' && 
				<h2 id={props.article.slug}>
					{props.article.title}
				</h2>
			}

			{ props.article.subtitle !== '' && 
				<h3>{props.article.subtitle}</h3>
			}

			{/*<pre>{JSON.stringify(props.article)}</pre>*/}

			{ props.article.media.length > 0 && 
				<div className="article__media">
					{props.article.media.map( (media, mediaIdx) => 
						<div key={`article_media_${mediaIdx}_${media.id}`} ref={el => itemsRef.current[`media_${mediaIdx}_${media.id}`] = el}>
							<Media 
								key={`media_${mediaIdx}`} 
								media={media} 
								cdnParams="&width=600&func=crop"
							/>
						</div>
					)}
				</div>
			}
			
			{ props.article.description !== '' && 
				<p ref={descriptionRef} dangerouslySetInnerHTML={{__html: props.article.description}} />
			}

			{props.showMoreButton && pCount > 1 && 
				<button 
					className="btn btn--more" 
					onClick={(e) => {
						let btnElem = e.target;
						btnElem.closest('.section').classList.add('section--expanded');
						btnElem.remove();
					}}
				>
					{translations[props.lang].read_more}
				</button>
			}
			
		</div>
	);

}

export default ArticleDefault;