import { Fragment, useEffect, useState } from 'react';
import { BrowserRouter /*,Routes, Route, NavLink*/ } from "react-router-dom";
import history from './../history';
import axios from 'axios';
import { 
    apiUrl, 
    defaultLang 
} from '../Helpers/General';
import RouterWrapper from '../Containers/RouterWrapper';


import ArticleDefault from 				'./../Components/Articles/ArticleDefault';

import Home from                        './../Pages/Home';
import Footer from                      './../Components/Footer';


function Main() {
    
    const [lang, 	setLang] = useState(null);
    const [lists, 	setLists] = useState([]);
    const [menu, 	setMenu] = useState([]);
    
    const [data,    setData] = useState(null);
    const [page,    setPage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    
    useEffect(() => {
        let urlSegments = window.location.href.split('/');
        let lang = ( urlSegments[3] !== undefined && urlSegments[3] !== '' ) ? urlSegments[3] : defaultLang;
        setLang( lang );
    },[])
    
    useEffect(() => {
        if( lang !== null ){
            axios.get(`${apiUrl}${lang}/api/menus`)
                .then(res => res.data)
                .then(data => {
                    setMenu(data);
                });

            axios.get(`${apiUrl}${lang}/api/lists`)
                .then(res => res.data)
                .then(data => {
                    setLists(data);
                });

            fetchContent( {slug: (lang==='hr') ? 'pocetna' : 'homepage', type: 'pages'} )
        }
    // eslint-disable-next-line
    },[lang])

    

    const fetchContent = ( params ) => {
        if( lang !== null ){
            setIsLoading(true);
            axios.get(`${apiUrl}${lang}/api/${params.type}/${params.slug}${(params.hasSub===true)?'?sub=true':''}`)
                .then(res => res.data)
                .then(data => {
                    setData(data);
                    setPage(null);
                    setIsLoading(false);
                });
        }
    }

    const fetchCustomPage = ( params ) => {
        const pagesMap = {
            pocetna: <Home />,
        };
        
        setPage( pagesMap[params.slug] );
        setData(null);
    }


    const renderCategories = ( categories, displayLevel ) => {

        return categories
            .map( (list, listIdx) => 
                <div key={`home_cat_${listIdx}`} data-sub-level={displayLevel}>

                    <button
                        key={`menu_list_${listIdx}`}
                        onClick={() => fetchContent({
                            slug: list?.translations[0]?.slug, 
                            type: 'items',
                            hasSub: false,
                        })}
                    >
                        {list.translations[0]?.title}
                    </button>

                    {renderCategories( 
                        lists.filter(x => x.parent_id === list.id), 
                        displayLevel + 1
                    )}

                </div>
        )
    }


    return (

        <div className="main">

            <BrowserRouter history={history} >
				<RouterWrapper>


                    <div className="wrapper">

                        <div className="sidebar">
                            
                            {menu
                                .filter( x => x.slug !== '')
                                .map( (menuLink, pageIdx) => {
                                return <button
                                    key={`menu_page_${pageIdx}`}
                                    onClick={
                                        (menuLink.in_routes === "1") 
                                            ? () => fetchContent( {slug: menuLink.slug, type: 'pages'} )
                                            : () => fetchCustomPage( {slug: menuLink.slug} )
                                    }
                                >
                                    {menuLink.title}
                                </button>
                            })}

                            { renderCategories( lists.filter(x => x.parent_id === "0" ), 1) }

                        </div>

                        <div className="content">

                            {isLoading === true && <pre>LOADING CONTENT...</pre>}

                            {isLoading === false && page === null && data !== null && data.map( (dataItem, dIdx) => 
                                <Fragment key={`itm_${dIdx}`}>
                                    {dataItem.translations.map( (trns, tIdx) => 
                                        <ArticleDefault 
                                            key={`art_${tIdx}`}
                                            article={trns} 
                                            dataItem={dataItem}
                                        />
                                    )}
                                </Fragment>
                            )}

                            {isLoading === false && page !== null && page }

                            {isLoading === false && data !== null && data.length === 0 && 
                                <pre>no content</pre>
                            }

                        </div>

                    </div>

                    <Footer 
                        lang={lang}
                        setLang={setLang}
                    />

                </RouterWrapper>
            </BrowserRouter>

        </div>

    );
}
    
export default Main;