import {Fragment, useEffect, useState} from "react";
// import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { apiUrl } from '../Helpers/General';




function Home(props){

	
	// eslint-disable-next-line
	const [data, setData] = useState([]);


	useEffect(() => {
		window.scrollTo(0, 0);

		if( props.lang !== undefined && props.lang !== '' ){
			axios.get(`${apiUrl}${props.lang}/api/lists`)
				.then(res => res.data)
				.then(data => {
					setData(data)
					console.log(data);
				});
		}
	// eslint-disable-next-line
	},[props.lang, window.location.hash])




	return (
		<Fragment>
			
			<div className="page page--home" >

				homepage

			</div>
		
		</Fragment>
	);

}

export default Home;