const isLocal = false /*Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('192.168.') ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
*/

export const baseUrl = (isLocal) 
	? 'http://localhost/cms_ml/' 
	: 'https://nkrgovic.borisvidosevic.hr/';

export const apiUrl = (isLocal) 
	? `${baseUrl}`
	: `https://nkrgovic.borisvidosevic.com/`;

export const mediaUrl = (isLocal) 
	? `https://eu2.contabostorage.com/develop/`
	: `https://eu2.contabostorage.com/1fe10eb1de0d423e90a8bac8eb977288:nkrgovic/`;

export const cdnUrl = (isLocal) 
    ? `https://agewvlkkgp.cloudimg.io/v7/_develop_/`
    : `https://agewvlkkgp.cloudimg.io/v7/_nkrgovic_/`;

export const cdnParams = `?force_format=jpeg`;

export const defaultLang = 'en';